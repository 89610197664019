import { combineReducers } from 'redux';

import articleReducer from './Article/article-reducer';
import metatagReducer from './MetaTags/metatag-reducer';
// import authReducer from './Auth/auth-reducer';
// import shopReducer from './Shopping/shopping-reducer';
import legalServiceReducer from './LegalService/legalservice-reducer';

const rootReducer = combineReducers({
    /**
     * Article
     * Legal Services
     * Business Entity
     * Package Order
     */
    article: articleReducer,
    metatag: metatagReducer,
    legalService: legalServiceReducer
});

export default rootReducer;