import React from 'react';
import './B2BPackageHeader.scss';

import Init from '../../../Init/Init';
const { sectionAssets } = Init.config;

function B2BPackageHeader(props) {
    const header = {
        title: `Layanan Hukum untuk melindungi bisnis sobat`,
        description: `Landack. adalah pelopor <em class='highlighted'>Legal Service Aggregator</em> yang menghadirkan jasa konsultasi dan layanan hukum yang reliabel dan terjangkau untuk perusahaan maupun perorangan.`
    };

    return (
        <div className="b2b-package-header">
            {/* 
                Header
                1. Mascot
                2. Content
            */}
            <div className="b2b-package-header-asset">
                <img src={ sectionAssets.b2bPackage.subMain } alt="" />
            </div>
            <div className="b2b-package-header-content">
                <h1 className='header-content-title' dangerouslySetInnerHTML={{__html: header.title}}>
                </h1>
                <div className='header-content-description'> 
                    <span dangerouslySetInnerHTML={{__html: header.description}}>
                    </span>
                </div>
                
                <div className="header-content-cta-container">
                    <a href="#enterprise" className='header-content-cta-button'> Landack. Full Service </a>
                    <a href="#legal-boost" className='header-content-cta-button'> Landack. Legal Boost </a>
                    <a href="#membership" className='header-content-cta-button'> Landack. Membership </a>
                </div>
                {/* <ConsultButton customClass="header-content-cta-button" text={ footer.ctaButton.text } href={ footer.ctaButton.url } /> */}
            </div>
        </div>
    );
}

export default B2BPackageHeader;