import React from 'react';
import ArticleCarousel from '../ArticleCarousel/ArticleCarousel';
import ArticleList from '../ArticleList/ArticleList';

import './Articles.scss';

const Articles = (props) => {
    return (
        <div id="article" className='article-container'>
            <div className="article-container-header">
                <h2 className='article-container-title'>
                    Artikel
                </h2>
            </div>
            {/* <ArticleCarousel /> */}
            <ArticleList />
        </div>
    );
}

export default Articles;