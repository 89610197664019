import { legalServiceList } from '../../Data/LegalService';
import * as actionTypes from './legalservice-types';

const loadLegalServices = () => {
    return dispatch => {
        legalServiceList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadLegalServiceSuccess({
                    data
                }));
            }
        } );
    }
}

const loadLegalServiceSuccess = (data) => {
    return {
        type: actionTypes.LEGAL_SERVICE_LIST,
        payload: {
            ...data
        }
    }
} 

const legalServiceActions = {
    loadLegalServices
}

export default legalServiceActions;