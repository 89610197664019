import React, {Component} from 'react';
import './FAQPage.scss';

import Init from '../../Init/Init';

import FAQ from '../../Component/PagesComponent/FAQ/FAQ';

class FAQPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {}
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }

    render() {
        return (
            <div className="faq-page">
                <FAQ />
            </div>
        );
    }
}

export default FAQPage;