import Init from '../Init/Init';
import { responseFormat } from '../Util/Util';
import api from './API';

const pathList = {
    order: `transaction/order`,
    authPayment: `cashbac/payment/:param`,
    capturePayment: `cashbac/capture/:param`
};

const paymentAggregator = Init.constants.paymentService;

export const authPayment = async (transactionCode, token, userPubId, paymentMethodPubId, email) => {
    try {
        const apiPath = pathList.authPayment;
        const response = await api.postCall(apiPath, transactionCode, {
            token,
            userPubId,
            paymentMethodPubId,
            email
        });

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw response.message;
        }

        return responseFormat(response.errorCode, 'Create transaction success', data);
    } catch(e) {
        return responseFormat(-1, e.toString(), {});
    }
};

export const order = async (client_name, service_code, service_type, quantity) => {
    try {
        const apiPath = pathList.order;
        const response = await api.postCall(apiPath, null, {
            client_name,
            service_code,
            service_type,
            quantity,
            payment_aggregator: paymentAggregator.cashbac,
            transaction_notes: ''
        });

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw new Error("Failed to create order");
        }

        return responseFormat(response.errorCode, 'Create order success', data);
    } catch(e) {
        return responseFormat(-1, 'Failed to create order!', {});
    }
};

export const capturePayment = async (
    transactionCode,
    token,
    userPubId,
    paymentMethodPubId,
    txPin
) => {
    try {
        const apiPath = pathList.capturePayment;
        const response = await api.postCall(apiPath, transactionCode, {
            token,
            userPubId,
            paymentMethodPubId,
            txPin
        });

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw response.message;
        }

        return responseFormat(response.errorCode, 'Confirm transaction success', data);
    } catch(e) {
        return responseFormat(-1, e.toString(), {});
    }
}