import React, { useState, useEffect } from 'react';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';
import { starterKitList, starterKitGetAll } from '../../../Data/StarterKit';

import './StarterKit.scss';

import { buildWaLink, numberFormatting, packageLink } from '../../../Util/Util';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Init from '../../../Init/Init';

const { imageLogoText } = Init.config;

const StarterKit = (props) => {
    const [ starterKit, setStarterKit ] = useState([]);
    const [ services, setServices ] = useState([]);

    const gaEventCategory = 'Starter Kit';
    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    // useEffect( () => {
    //     if( starterKit.length > 0 ) {
    //         const completePackageIndex = starterKit.reduce((max, item, index) => {
    //             return starterKit[max].descriptionList.length > item.descriptionList.length ? max : index; 
    //         }, 0);
    //         const completePackage = starterKit[completePackageIndex].descriptionList;
    
    //         setServices(completePackage);
    //     }
    // }, [starterKit]);

    useEffect( () => {
        ( async () => {
            const response = await starterKitGetAll();
            
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = [];

                for(let index in response.data) {
                    let item = response.data[index];
                    // let packagePricing = item.package_pricing;
                    let totalRealPrice = parseInt(item.fake_price);
                    let savedPrice = parseInt(item.package_pricing[0].monthly_price);

                    data.push({
                        name: item.package_name,
                        icon: `${process.env.REACT_APP_API_PACKAGE_STORAGE}${item.icon_path}`,
                        // descriptionList: item.package_service,
                        description: item.package_description,
                        priceObj: {
                            realPrice: totalRealPrice,
                            discountPrice: savedPrice,
                            savePercentage: Math.floor(((totalRealPrice - savedPrice)/totalRealPrice) * 100) + '%'
                        },
                        // priceList: packagePricing,
                        isRecommended: item.is_recommended,
                        isBestSeller: item.is_best_seller,
                        buttonLink: packageLink(`${item.package_code}`),
                        buttonWa: buildWaLink('membutuhkan', `"${item.package_name}"`),
                        actionLabel: ''
                    });
                }

                setStarterKit(data);
            }
        } )();
    }, [] );

    return (
        <div id="starter-kit" className='starter-kit'>
            <h2 className='starter-kit-title'>
                {/* <img src={ imageLogoText } />  */}
                Landack. Starter Kit
            </h2>
            {
                starterKit.length > 0 ? (
                    <div className="starter-kit-cards">
                        {
                            starterKit.map((v, k) => {
                                return (
                                    <div key={k} className={"starter-kit-item" + (v.hasOwnProperty('isRecommended') && v.isRecommended ? ' is-recommended' : '') + (v.hasOwnProperty('isBestSeller') && v.isBestSeller ? ' is-best-seller' : '')}>
                                        <div className="starter-kit-icon-container">
                                            <img src={v.icon} alt="Icon Layanan" className='starter-kit-item-icon' />
                                        </div>
                                        <h3 className="starter-kit-item-title">
                                            { v.name }
                                        </h3>
                                        {
                                            v.hasOwnProperty('priceObj') ? (
                                                <>
                                                    <span className='starter-kit-item-price'>
                                                        <span className=' starter-kit-item-discount-price'>
                                                            { numberFormatting(v.priceObj.discountPrice) } 
                                                        </span>
                                                    </span>
                                                    <h5 className='starter-kit-item-price'>
                                                        Hemat {
                                                                v.priceObj.realPrice > 0 ? (
                                                                    <span className='starter-kit-item-real-price'>{ numberFormatting(v.priceObj.realPrice - v.priceObj.discountPrice) } </span>
                                                                ) : ( '' )
                                                            }
                                                    </h5>
                                                </>
                                            ) : ''
                                        }
                                        <a 
                                            className="btn-starter-kit" 
                                            target='_blank'
                                            href={ v.buttonWa } 
                                            onClick={ () => gaEventTracker('Click Order Now Button', v.actionLabel)}
                                            onMouseEnter={ () => gaEventTracker('Hover Order Now Button', v.actionLabel)}
                                        >
                                            Pesan Sekarang
                                        </a>
                                        {
                                            v.hasOwnProperty('description') ? (
                                                <p className="starter-kit-item-desc" dangerouslySetInnerHTML={{__html: v.description}}>
                                                    {/* { v.description } */}
                                                </p>
                                            ) : ''
                                        }
                                        {
                                            v.hasOwnProperty('descriptionList') ? (
                                                <ul className='starter-kit-item-desc-list'>
                                                    {
                                                        services.map( (point, index) => {
                                                            let serviceExists = v.descriptionList.hasOwnProperty(index);
                                                            let existIcon = serviceExists ? '✅' : '❌';
                                                            let wording = '';
                
                                                            if(serviceExists) {
                                                                let item = v.descriptionList[index];
                
                                                                let servicePortion = `${ item.portion > 1 ? `${item.frequency} x ${item.portion} ${item.unit}` : `${item.frequency} ${item.unit}`}`;
                                                                servicePortion = item.frequency * item.portion > 0 ? `(${servicePortion})` : 'Tanpa Batas';
                
                                                                wording = `${ point.service_name } ${ servicePortion }`;
                                                            } else {
                                                                wording = point.service_name;
                                                            }
                
                                                            return (
                                                                <li key={index} className="starter-kit-item-desc">
                                                                    {existIcon} { wording }
                                                                </li>
                                                            );
                                                        } )
                                                    }
                                                </ul>
                                            ) : ''
                                        }
                                        {/* <br /> */}
                                        {
                                            v.hasOwnProperty('priceList') ? (
                                                <>
                                                    {
                                                        v.priceList.map( (item, index) => {
                                                            return ( 
                                                                <span key={index} className='starter-kit-item-price'>
                                                                    { (v.priceList.length > 1 ? `${item.duration_in_months} Bulan: ` : '') + numberFormatting(item.monthly_price) } / bulan
                                                                </span>
                                                            )
                                                        } )
                                                    }
                                                </>
                                            ) : ''
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                ) : (
                    <div className="starter-kit-loading-section">
                        <LoadingPage />
                    </div>
                )
            }

        </div>
    );
}

export default StarterKit;