import React from 'react';
import Init from '../../../Init/Init';
import ContactInfo from '../../../Data/Contact';

import { Link } from 'react-router-dom';

import './ConsultButton.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import { buildWaLink, legalServiceLink } from '../../../Util/Util';

const ConsultButton = (props) => {
    const { FontAwesomeIcon, config } = Init;
    const { iconFolder } = config;
    const { 
        isFixed, 
        customClass, 
        show, 
        withIcon,
        text,
        href,
        buttonName = isFixed ? 'Fixed Button' : 'Header Button',
        onClick
    } = props;
    const info = ContactInfo();

    const gaEventCategory = buttonName;

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    // const waLink = '/coming-soon';
    // const waLink = process.env.REACT_APP_ENV === 'production' ? buildWaLink('melakukan', 'Konsultasi Hukum') : legalServiceLink('KH');
    const waLink = legalServiceLink('KH');
    const favicon = `${iconFolder}/favicon.png`;

    const clickHandler = (event) => {
        gaEventTracker('Click', text);
        
        if( onClick !== null ) {
            event.preventDefault();
            onClick();
        }

    }

    return (
        <a 
            target="_blank" 
            className={`btn-consult ${show ? '' : 'd-none'} ${isFixed ? 'is-fixed' : ''} ${customClass}`} 
            href={href !== '' ? href : waLink} 
            onClick={ (event) => clickHandler(event) }
            onMouseEnter={() => gaEventTracker('Hover', text)}
        >
            {/* <FontAwesomeIcon className='btn-consult-icon' icon={['fab', 'whatsapp']} />  */}
            <img className={`btn-consult-icon ${ withIcon ? '' : 'd-none' }`} src={favicon} alt="" /> <span className='btn-consult-text'>{ text }</span>
        </a>
    );
}

ConsultButton.defaultProps = {
    isFixed: false,
    customClass: '',
    show: true,
    withIcon: false,
    text: 'Konsultasi Sekarang!',
    href: '',
    onClick: null
}

export default ConsultButton;