import { responseFormat } from '../Util/Util';
import api from './API';

const basePath = 'package';
const pathList = {
    list: `/all`,
    detail: `/:param`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

export const packageList = async () => {
    try {
        const apiPath = pathList.list;

        const response = await api.getCall(apiPath);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        }

        return responseFormat(response.errorCode, 'Fetch packages success', data);
    } catch(e) {
        return responseFormat(-1, 'Failed to get packages!', {});
    }
};

export const packageDetail = async (id) => {
    try {
        const apiPath = pathList.detail;
        const response = await api.getCall(apiPath, id);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        }

        return responseFormat(0, 'Fetch package success', data);
    } catch(e) {
        return responseFormat(-1, 'Failed to get package!', {});
    }
}