import React, {Component, Fragment, createRef} from 'react';
import { Image, Col, Row, Form, Button } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './Footer.scss';

import FormInput from '../../Utilities/FormInput/FormInput';

import ContactInfo from '../../../Data/Contact';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Footer Contact';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
}

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerInfo: ContactInfo(),
            footerForm: {
                name: {
                    label: "Nama",
                    value: createRef(),
                    width: 6, // x < 12
                    type: "text",
                    required: true,
                },
                email: {
                    label: "Email",
                    value: createRef(),
                    width: 6, // x < 12
                    type: "text",
                    required: true,
                },
                phone: {
                    label: "No. Telp",
                    value: createRef(),
                    width: 6, // x < 12
                    type: "text",
                },
                // companyName: {
                //     label: "Company Name",
                //     value: createRef(),
                //     width: 6, // x < 12
                //     type: "text",
                // },
                subject: {
                    label: "Subjek",
                    value: createRef(),
                    width: 12,
                    // placeholder: "Type your subject",
                    placeholder: "Ketik subjek disini",
                    type: "text",
                },
                body: {
                    label: "Pesan",
                    value: createRef(),
                    width: 12,
                    // placeholder: "Type your message here",
                    placeholder: "Ketik pesanmu disini",
                    type: "textarea"
                },
            },
            prohibitSubmit: true
        };

        this.config = Init.config;
        this.formData = {};
    }

    formUpdate(name, value) {
        this.formData[name] = value;

        if(
            ( this.formData.hasOwnProperty('name') && this.formData.name ) 
            && 
            (this.formData.hasOwnProperty('email') && this.formData.email) 
        ) {
            this.prohibitSubmit(false);
        } else {
            this.prohibitSubmit();
        }
    }

    prohibitSubmit(prohibit = true) {
        let state = this.state;
        state.prohibitSubmit = prohibit;

        this.setState(state);
    }

    recursiveIteration(data, keys, result = '', counter = 0) {
        let max = keys.length;

        if(counter >= max) {
            return result;
        } else {
            return keys[counter] + "=" + encodeURIComponent(data[keys[counter]]) + "&" + this.recursiveIteration(data, keys, result, counter + 1);
        }
    }

    formSubmit(event) {
        this.trackGa('Click Submit Button', 'Form Submission');

        let state = this.state;
        let queryString = "";
        let formDataKeys = Object.keys(this.formData);
        let contactInfo = state.footerInfo;
        
        queryString = this.recursiveIteration(this.formData, formDataKeys);

        window.open("mailto:" + contactInfo.email + "?" + queryString.substring(0, queryString.lastIndexOf("&")), "_blank");

        let formElementKeys = Object.keys(state.footerForm);

        for(let i = 0; i < formElementKeys.length; i++) {
            let key = formElementKeys[i];
            state.footerForm[key].value.current.value = "";
        }

        this.prohibitSubmit(true);
    }

    trackGa(action, label) {
        GaTracker({
            action,
            label
        });
    }

    render() {
        let state = this.state;
        let config = this.config;
        let footerInfo = state.footerInfo;
        let FontAwesomeIcon = Init.FontAwesomeIcon;
        let footerForm = state.footerForm;

        let contactInfo = [
            {
                title: "Alamat",
                content: [
                    {
                        contentTitle: "PT Inspirasi Teknologi Bangsa",
                        content: footerInfo.address
                    }
                ]
            },
            {
                title: "Landack.",
                content: [
                    {
                        content: (<a key={0} onClick={() => this.trackGa('Click Terms and Condition', 'Open Terms and Condition')} target='_blank' rel="noopener noreferrer" className={"with-underline fade-in"} href={`/terms-and-condition`}> Syarat & Ketentuan </a>)
                    },
                    {
                        content: (<a key={1} onClick={() => this.trackGa('Click Privacy Policy', 'Open Privacy Policy')} target='_blank' rel="noopener noreferrer" className={"with-underline fade-in"} href={`/privacy-policy`}> Kebijakan Privasi </a>)
                    }
                ]
            },
            {
                title: "Hubungi Kami",
                content: [
                    {
                        content: (<a key={0} onClick={() => this.trackGa('Click Email Address', 'Open Email')} target='_blank' rel="noopener noreferrer" className={"with-underline fade-in"} href={"mailto:" + footerInfo.email}> {footerInfo.email} </a>)
                    },
                    // {
                    //     content: (<a key={1} onClick={() => this.trackGa('Click Phone Number', 'Direct to Whatsapp')} target='_blank' rel="noopener noreferrer" className={"with-underline fade-in"} href={"tel:" + footerInfo.phoneno}> {footerInfo.phoneno} </a>)
                    // }
                ]
            },
            {
                title: footerInfo.dirjen.title,
                width: {
                    lg: 12,
                    md: 12
                },
                center: true,
                content: [
                    {
                        contentTitle: footerInfo.dirjen.company,
                        content: `Email: ${footerInfo.dirjen.email}`
                    },
                    {
                        content: `${footerInfo.dirjen.description[0]}`
                    },
                    {
                        content: `${footerInfo.dirjen.description[1]}`
                    },
                    {
                        content: ( <>
                            Whatsapp Ditjen PKTN: <a key={0} onClick={() => this.trackGa('Click Customer Complaint', 'Open Customer Complaint')} target='_blank' rel="noopener noreferrer" className={"with-underline fade-in"} href={"tel:" + footerInfo.dirjen.whatsapp}> {footerInfo.dirjen.whatsapp} </a>
                        </> )
                    }
                ]
            }
        ];

        return (
            <>
                <footer id="contact" className={
                    "footer-contact" 
                    // + 
                    // (this.props.show ? '' : ' d-none')
                }>
                    <h2 className="footer-contact-title">
                        LOKASI
                    </h2>
                    <div className="footer-contact-content">
                        <div className="content-first-column">
                            <div className="contact-image-container">
                                <iframe onClick={ () => this.trackGa('Click Map', 'Google Maps') } src={footerInfo.maps.link} width="100%" height="350" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <Row className="contact-info-container">
                                    {
                                        contactInfo.map( (item, index) => {
                                            return (
                                                <Col key={index} lg={ item.hasOwnProperty('width') ? item.width.lg : 4 } md={ item.hasOwnProperty('width') ? item.width.md : 6 } className={`contact-info-item mb-4 ${ item.hasOwnProperty('center') && item.center ? 'center' : '' }`}>
                                                    <h3>
                                                        {item.title}
                                                    </h3>
                                                    {
                                                        item.content.map((v, k) => {
                                                            return (
                                                                <Fragment key={k}>
                                                                    { v.hasOwnProperty('contentTitle') ? (
                                                                        <h4>
                                                                            {v.contentTitle}
                                                                        </h4>
                                                                    ) : '' }
                                                                    <div className="contact-info-content">
                                                                        {v.content}
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            )
                                        } )
                                    }
                            </Row>
                            <div className="footer-contact-socmed contact-info-item">
                                <h3>Ikuti Kami</h3>
                                <div className="contact-info-content">
                                {
                                    footerInfo.socmed.map((item, index) => {
                                        return (
                                            <a key={index} onClick={() => this.trackGa('Click Social Media', item.name)} target='_blank' rel="noopener noreferrer" className={"fade-in"} href={item.link}>
                                                <FontAwesomeIcon icon={item.faIcon} /> 
                                            </a>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="footer-form content-second-column d-none">
                            {/* 
                                Form Send to Email
                            */}
                            <Form className='row'>
                                {
                                    Object.keys(footerForm).map( (key, index) => {
                                        let item = footerForm[key];

                                        return (
                                            <FormInput 
                                                key={index} 
                                                attribute={item}
                                                name={key} 
                                                onChange={ (name, value) => this.formUpdate(name, value) } 
                                            />
                                        );
                                    } )
                                }

                                <Col md={12}>
                                    <Button onClick={(event) => this.formSubmit(event)} disabled={state.prohibitSubmit} className='btn-contact-form'>
                                        Kirim
                                    </Button>
                                </Col>
                                
                            </Form>
                        </div>
                    </div>
                    <div className="contact-info-item copyright">
                        © 2022 Landack by PT ITB. All rights reserved.
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;