import * as actionTypes from './legalservice-types';

const INITIAL_STATE = {
    data: []
}

const legalServiceReducer = (state = INITIAL_STATE, action) => {
    let { data } = state;
    
    switch(action.type) {
       case actionTypes.LEGAL_SERVICE_LIST:
            data = action.payload.data;

            return {
                ...state,
                data
            };
        default: 
            return state;
    }
};

export default legalServiceReducer;