import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TransactionResultRedirection = (props) => {
    // const deepLink = `landackapp://payment-report/${transactionCode}`;
    const params = useParams();
    const [ deepLink, setDeepLink ] = useState(null);

    useEffect(() => {
        const { transactionCode } = params;

        if( transactionCode ) {
            // window.location.href = `landackapp://payment-report/${transactionCode}`;
            setDeepLink(`landackapp://payment-report/${transactionCode}`);
        }

    }, []);

    return (
        <div className='transaction-result-redirection'>
            {
                deepLink !== null ? window.location.replace(deepLink) : ''
            }
            {/* window.location.replace(waLink) */}
        </div>
    );
}

export default TransactionResultRedirection;