import React from 'react';
import Init from '../../../Init/Init';
import ConsultButton from '../../Utilities/ConsultButton/ConsultButton';

import './BusinessEntityFooter.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const { config } = Init;

const BusinessEntityFooter = (props) => {
    const { sectionAssets } = Init.config;
    const gaEventCategory = 'Pendirian Badan Usaha';
    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    return (
        <div className='business-entity-footer'>
            {/* 
                2 parts:
                1. Title
                2. Button to redirect to another page
            */}
            <div className="business-entity-footer-assets">
                <img src={sectionAssets.businessEntity.confused} alt="" />
            </div>
            <div className="business-entity-footer-header">
                <h2>
                    Masih bingung mau pilih yang mana?
                </h2>
            </div>
            <div className="business-entity-footer-button-container">
                {/* <ConsultButton withIcon={false} customClass="btn-business-entity-footer" /> */}
                <a rel="noopener" target="__blank" href={ config.googlePlayLink } className="btn-download btn-download-android" onClick={ () => gaEventTracker('Download App', "Google Play") }>
                    <img src={ config.googlePlayIcon } alt="Google Play Icon" /> Konsultasi di Android
                </a>
                <a rel="noopener" target="__blank" href={ config.appStoreLink } className="btn-download btn-download-ios" onClick={ () => gaEventTracker('Download App', "Apple App Store") }>
                    <img src={ config.appStoreIcon } alt="App Store Icon" /> Konsultasi di iOS
                </a>
            </div>
        </div>
    );
}

export default BusinessEntityFooter;