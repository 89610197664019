import React, { useEffect, useState, Fragment } from 'react';
import './Testimony.scss';

import Init from '../../../Init/Init';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Testimony from '../../../Data/Testimony';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const { FontAwesomeIcon, config } = Init;
const { quoteIcon, thumbupIcon } = config;

const TestimonyList = (props) => {
    const { data } = props;
    
    const gaEventCategory = 'Testimoni';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);
    
    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "testimony-item-container";
    carouselConfig.itemClass = "testimony-item-holder";
    carouselConfig.showDots = true;
    carouselConfig.infinite = false;
    carouselConfig.dotListClass = 'testimony-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1550, min: 1200 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        // 'desktop',
        // 'tablet',
        // 'mobile'
    ];

    return (
        <Carousel
                // arrows={false}
                // renderButtonGroupOutside={true}
                // customButtonGroup={<ButtonGroup />}
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}
            >
                {
                    data.map( (v, k) => {
                        return (
                            <div key={k} className="testimony-item">
                                {/* 
                                    1. Censored name with quote on the top left
                                    2. Testimony
                                    3. Thumbs
                                    3. Star
                                */}
                                <div className="quote-icon">
                                    <img src={ quoteIcon } alt="Quote" />
                                </div>
                                <div className="reviewer-name">
                                    { v.reviewer }
                                </div>
                                <div className="review-text">
                                    { v.review }
                                </div>
                                <div className="review-rating">
                                    {
                                        Array.from({length: v.rating}, (_, i) => i + 1).map( (item, index) => {
                                            return (
                                                <FontAwesomeIcon key={index} className='rating-star' icon={['fas', 'star']} />
                                            )
                                        } )
                                    }
                                </div>
                            </div>
                        );
                    } )
                }
        </Carousel>
    );
}

const TestimonySection = () => {
    let { imageLogo } = config;

    const [ testimony, setTestimony ] = useState([]);

    useEffect( () => {
        setTestimony(Testimony());
    }, []);

    return (
        <div className="testimony">
            <h2 className='testimony-title'>
                Testimoni
            </h2>
            {
                testimony.length > 0 ? (
                    <TestimonyList data={testimony} />
                ) : (
                    <div className="testimony-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}



export default TestimonySection;