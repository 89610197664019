import React, {Component} from 'react';
import './Homepage.scss';

import Init from '../../Init/Init';

import Header from '../../Component/PagesComponent/Header/Header';
// import Identity from '../../Component/PagesComponent/Identity/Identity';
// import ComingSoon from '../../Component/PagesComponent/ComingSoon/ComingSoon';
import Advantages from '../../Component/PagesComponent/Advantages/Advantages';
import FAQ from '../../Component/PagesComponent/FAQ/FAQ';
import PricingInfo from '../../Data/Pricing';
import Articles from '../../Component/PagesComponent/Articles/Articles';
import BusinessEntity from '../../Component/PagesComponent/BusinessEntity/BusinessEntity';
import TestimonySection from '../../Component/PagesComponent/Testimony/Testimony';

import ReactGA from 'react-ga4';
import LegalService from '../../Component/PagesComponent/LegalService/LegalService';
import CorporatePackage from '../../Component/PagesComponent/CorporatePackage/CorporatePackage';
import B2BPackage from '../../Component/PagesComponent/B2BPackage/B2BPackage';
import Membership from '../../Component/PagesComponent/Membership/Membership';
import StarterKit from '../../Component/PagesComponent/StarterKit/StarterKit';
import Collaborator from '../../Component/PagesComponent/Collaborator/Collaborator';
import Client from '../../Component/PagesComponent/Client/Client';

class Homepage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    scrollEvent(event) {
        const sectionList = {
            header: {
                id: 'header',
                name: 'Header',
                min: document.getElementById('header').offsetTop,
                max: document.getElementById('header').offsetTop + document.getElementById('header').clientHeight
            },
            advantages: {
              id: 'advantage',
              name: 'Kenapa Landack.?',
              min: document.getElementById('advantage').offsetTop,
              max:  document.getElementById('advantage').offsetTop + document.getElementById('advantage').clientHeight
            },
            individualPricing: {
              id: 'individual-pricing',
              name: 'Layanan',
              min: document.getElementById('individual-pricing').offsetTop,
              max:  document.getElementById('individual-pricing').offsetTop + document.getElementById('individual-pricing').offsetHeight
            },
            corporatePricing: {
              id: 'b2b-section',
              name: 'Landack. Bisnis',
              min: document.getElementById('b2b-section').offsetTop,
              max:  document.getElementById('b2b-section').offsetTop + document.getElementById('b2b-section').offsetHeight
            },
            faq: {
              id: 'faq',
              name: 'FAQ',
              min: document.getElementById('faq').offsetTop,
              max:  document.getElementById('faq').offsetTop + document.getElementById('faq').offsetHeight
            },
            articles: {
              id: 'article',
              name: 'Artikel',
              min: document.getElementById('article').offsetTop,
              max:  document.getElementById('article').offsetTop + document.getElementById('article').offsetHeight
            },
            footer: {
              id: 'contact',
              name: 'Footer Contact',
              min: document.getElementById('contact').offsetTop,
              max:  document.getElementById('contact').offsetTop + document.getElementById('contact').offsetHeight
            }
        };
        
        const scrollState = window.scrollY;
        
        for(let key in sectionList) {
            let item = sectionList[key];

            if(scrollState > item.min && scrollState < item.max) {
                ReactGA.send({
                    hitType: "scroll",
                    page: `#${item.id}`,
                    title: item.name
                });

                break;
            }

        }
    }

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', function(){});
        window.removeEventListener('scroll', this.scrollEvent);
    }

    render() {
        return (
            <div className="homepage">
                <Header />
                {/* <Identity /> */}
                <Advantages />
                <LegalService />
                {/* <Membership /> */}
                <div id="b2b-section" className="b2b-section">
                    <BusinessEntity />
                    <B2BPackage />
                </div>
                {/* <CorporatePackage /> */}
                {/* <StarterKit /> */}
                <Client />
                <Collaborator />
                <FAQ />
                <TestimonySection />
                <Articles />
                {/* <ComingSoon title="Artikel"/> */}
            </div>
        );
    }
}

export default Homepage;