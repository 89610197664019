import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './Client.scss';

const client = Init.config.sectionAssets.client;

const Client = (props) => {
    const [ partners, setPartners ] = useState([]);

    useEffect( () => {
        setPartners([
            {
                logoPath: `${client.propertiv}`,
                name: "CV Propertiv Innovatif Kreatif"
            },
            {
                logoPath: `${client.reblock}`,
                name: "Reblock.Finance"
            },
            {
                logoPath: `${client.gpa}`,
                name: "PT GRHA Prima Agung"
            },
            {
                logoPath: `${client.tpm}`,
                name: "PT Trimitra Putra Mandiri"
            },
            {
                logoPath: `${client.tpml}`,
                name: "PT Tunas Perkasa Muda Logistik"
            },
            {
                logoPath: `${client.listijo}`,
                name: "Mr. Listijo Laksono"
            }
        ]);
    }, []);

    return (
        <div className='client-section' id="clients">
            {/* 
                Title
                List of logos (LIKE PRP)
            */}
            <div className="client-header">
                <h2 className='client-title'> Klien Kami </h2>
            </div>
            <Row className="client-partners">
                {
                    partners.map( (item, index) => {
                        return (
                            <Col md={2} sm={3} xs={6} className="partner-item">
                                <div className="partner-logo">
                                    <img src={ item.logoPath } />
                                </div>
                                <div className="partner-name">
                                    {item.name}
                                </div>
                            </Col>
                        )
                    } )
                }
            </Row>
        </div>
    );
}

export default Client;