import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {Col, FormLabel, FormGroup, FormControl, Form} from 'react-bootstrap';

import './CheckoutPage.scss';

import Init from '../../Init/Init';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';
import { buildWaLink, numberFormatting, objectIsEmpty, lcFirst, serializedURL } from '../../Util/Util';
import { legalServiceDetail } from '../../Data/LegalService';
import { businessEntityDetail } from '../../Data/BusinessEntity';
import { packageDetail } from '../../Data/Package';
import { order } from '../../Data/Transaction';

const CheckoutPage = (props) => {
    const [ service, setService ] = useState({});
    const [ totalPrice, setTotalPrice ] = useState(0);
    const [ chosenPortion, setChosenPortion ] = useState(0);
    const [ disableCheckout, setDisableCheckout ] = useState(true);

    const { FontAwesomeIcon } = Init;

    const phoneNoRef = useRef();
    const { serviceType, serviceCode } = useParams();
    const { constants } = Init;

    const choices = [
        1,
        2,
        4,
        6,
        // 'custom'
    ]; // Frequency provided

    // useEffect(() => {

    // }, []);

    useEffect( () => {
        if(service.hasChoices) {
            setTotalPrice(service.servicePrice[chosenPortion].price);
        } else {
            setTotalPrice(service.servicePrice);
        }
    }, [service, chosenPortion]);

    useEffect( () => {
        (async () => {
            let response = null;
            let serviceData = {
                serviceType,
                serviceCode,
                serviceIconPath: '',
                serviceTitle: '',
                serviceDesc: '',
                servicePrice: [
                    /**
                     * {
                     *  frequency: 1,
                     *  portion: 0,
                     *  price: 
                     * }
                     */
                ],
                hasChoices: true
            };

            switch(parseInt(serviceType)) {
                case constants.serviceType.legalService:
                    response = await legalServiceDetail(serviceCode);
                    if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                        serviceData.serviceTitle = response.data.service_name;
                        serviceData.serviceDesc = response.data.description;
                        serviceData.serviceCode = serviceCode;
                        serviceData.serviceType = serviceType;
                        serviceData.serviceIconPath = `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${response.data.icon_path}`;
                        serviceData.unit = response.data.unit;
                        serviceData.servicePrice = choices.map((item, index) => {
                            return {
                                frequency: item,
                                price: item !== 'custom' ? item * response.data.price : 0,
                                portion: item !== 'custom' ? item * response.data.portion : 'Custom'
                            };
                        });
                    }
                    break;
                case constants.serviceType.package:
                    response = await packageDetail(serviceCode);
                    
                    if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                        serviceData.serviceTitle = response.data.package_name;
                        serviceData.serviceDesc = response.data.package_service.map( (item, index) => {
                            let frequencyPortion = item.frequency > 1 ? `${ item.frequency} x ${item.portion} ${item.unit}` : item.portion + 'x';
                            frequencyPortion = item.portion === 0 ? 'Tanpa Batas' : frequencyPortion;
                            
                            return `${item.service_name} ${ frequencyPortion }`;
                        } ).reduce( (allStr, str) => {
                            return `${allStr}${str}<br /> `;
                        }, '');
                        serviceData.serviceCode = serviceCode;
                        serviceData.serviceType = serviceType;
                        serviceData.serviceIconPath = `${process.env.REACT_APP_API_PACKAGE_STORAGE}${response.data.icon_path}`;
                        serviceData.unit = 'bulan';
                        serviceData.servicePrice = response.data.package_pricing.map((item, index) => {
                            let frequency = item.duration_in_months;
                            let price = item.monthly_price;

                            return {
                                frequency: frequency,
                                price: frequency * price,
                                portion: frequency
                            };
                        });
                    }
                    break;
                case constants.serviceType.businessEntity:
                    response = await businessEntityDetail(serviceCode);
                    
                    if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                        serviceData.serviceTitle = response.data.business_entity_name;
                        serviceData.servicePrice = response.data.price;
                        serviceData.serviceCode = serviceCode;
                        serviceData.serviceType = serviceType;
                        serviceData.serviceIconPath = `${process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE}${response.data.icon_path}`;
                        serviceData.hasChoices = false;
                    }

                    break;
                default:
                    break;
            } 

            setService({...serviceData});
        })();
    }, [serviceType, serviceCode]);

    const choosePortion = (index) => {
        if(service.servicePrice[index].price !== 'Custom') {
            setChosenPortion(index);
        } else {
            // Popup Modal
        }
    };

    const checkUpdate = (event) => {
        let input = event.nativeEvent.data;
        
        if(event.nativeEvent.data !== null && isNaN(parseInt(input))) {
            phoneNoRef.current.value = event.target.value.replace(input, '');
        }
        
        setDisableCheckout(phoneNoRef.current.value === '');
        
    }

    const checkout = () => {
        if(!disableCheckout) {
            const request = {
                client_name: phoneNoRef.current.value,
                service_type: service.serviceType,
                service_code: service.serviceCode,
                quantity: choices[chosenPortion]
            };

            {/**
                1. Create payment first;
                2. Return transaction code;
                3. Embed to url params;
            */}

            (async() => {
                const response = await order(
                    request.client_name,
                    request.service_code,
                    parseInt(request.service_type),
                    request.quantity
                );
                
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    const url = process.env.REACT_APP_CASHBAC_LOGIN_PAGE.replace('{redirectUrl}', `${response.data.paymentLink}`).replace('{clientId}', `${process.env.REACT_APP_CASHBAC_CLIENT_ID}`);
                    const title = "Cashbac Login for Landack.";

                    cashbacPopup(url, title);
                }
            })();
        }
    };

    const cashbacPopup = (url, title) => {
        // const w = 500;
        // const h = 700;

        // const dualScreenLeft =
        // window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        // const dualScreenTop =
        //     window.screenTop !== undefined ? window.screenTop : window.screenY;

        // const width = window.innerWidth;
        // const height = window.innerHeight;

        // const systemZoom = width / window.screen.availWidth;
        // const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        // const top = (height - h) / 2 / systemZoom + dualScreenTop;
        // const newWindow = window.open(
        //     url,
        //     title,
        //     `
        //     scrollbars=yes,
        //     width=${w / systemZoom},
        //     height=${h / systemZoom},
        //     top=${top},
        //     left=${left}
        //     `
        // );

        // if (window.focus) newWindow.focus();

        window.open(url, '_blank');
    }

    return (
        <div className='checkout-page'>
            <h1 className='checkout-page-title'>
                Pemesanan Layanan
            </h1>
            <div className="checkout-page-body">
                <div className="checkout-page-body-desc">
                    <div className="checkout-page-body-desc-image">
                        <img src={ service.serviceIconPath } alt="" />
                    </div>

                    <div className="checkout-page-body-desc-container">
                        <div className="checkout-page-body-desc-title">
                            <h3>
                                { service.serviceTitle }
                            </h3>
                        </div>

                        <div className="checkout-page-body-desc-desc" dangerouslySetInnerHTML={{__html: service.serviceDesc}}>
                            
                        </div>
                    </div>

                </div>
                <div className="checkout-page-body-detail">
                    {
                        service.hasChoices ? (
                            <div className="checkout-page-body-detail-choice">
                                {
                                    service.servicePrice.map((item, index) => {
                                        return (
                                            <button 
                                                key={index}
                                                value={item.frequency} 
                                                className={`checkout-page-body-detail-choice-item ${ chosenPortion === index ? 'active' : '' }`}
                                                onClick={() => choosePortion(index)}
                                            >
                                                { item.portion } { item.portion === 'Custom' ? '' : service.unit }
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        ) : ''
                    }
                    <div className="checkout-page-body-detail-phone-no">
                        <label htmlFor="phone-no-input" className='phone-no-label'>
                            <FontAwesomeIcon icon={ ['fab', 'whatsapp'] } /> No. Whatsapp 
                        </label>
                        <div className="phone-no-input">
                            <FormControl 
                                name='phoneNo'
                                as='input'
                                type='tel' 
                                ref={phoneNoRef} 
                                required={true}
                                onChange={checkUpdate}
                                // isInvalid={hasError}
                            />
                        </div>
                        <div className="phone-no-arrow" onClick={() => phoneNoRef.current.focus()}>
                            <FontAwesomeIcon icon={ ['fas', 'arrow-right'] } />
                        </div>
                    </div>
                    <div className="checkout-page-body-detail-price">
                        <div className="price-item">
                            <span className="price-item-label"> Harga </span>
                            <span className='price-item-price'> { numberFormatting(totalPrice) } </span>
                        </div>
                        <hr />
                        <div className="price-item total">
                            <span className="price-item-label"> Total </span>
                            <span className='price-item-price'> { numberFormatting(totalPrice) } </span>
                        </div>

                        <div className="checkout-btn-container">
                            <button className={`btn-checkout ${ disableCheckout ? 'disabled' : '' }`} onClick={ () => checkout() }>
                                Pesan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutPage;