import React from 'react';
import { buildWaLink } from '../../Util/Util';

const WhatsappRedirection = (props) => {
    const waLink = buildWaLink('mau melakukan', 'konsultasi hukum');

    return (
        <div className='whatsapp-redirection'>
            {
                // waLink
                window.location.replace(waLink)
            }
        </div>
    );
}

export default WhatsappRedirection;