import { responseFormat } from '../Util/Util';
import api from './API';

const basePath = 'package/starter-kit';
const pathList = {
    list: `/`,
    all: `/all`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

export const starterKitList = async () => {
    try {
        const apiPath = pathList.list;

        const response = await api.getCall(apiPath);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        }

        return responseFormat(response.errorCode, 'Fetch starter kit list success', data);
    } catch(e) {
        return responseFormat(-1, 'Failed to get starter kit list!', []);
    }
};

export const starterKitGetAll = async () => {
    try {
        const apiPath = pathList.all;

        const response = await api.getCall(apiPath);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        }

        return responseFormat(response.errorCode, 'Fetch all starter kit success', data);
    } catch(e) {
        return responseFormat(-1, 'Failed to get all starter kit list!', []);
    }
};