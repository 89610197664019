import { getFormattedDatetime } from '../../Util/Util';
import * as actionTypes from './article-types';

const INITIAL_STATE = {
    data: {},
    amount: 0,
    detail: {}
}

const articleReducer = (state = INITIAL_STATE, action) => {
    let { data, amount, detail } = state;
    let newData, refresh = {};
    
    switch(action.type) {
       case actionTypes.ARTICLE_LIST:
            newData = action.payload.data;
            amount = action.payload.amount;
            refresh = action.payload.refresh;

            for( let index in newData ) {
                let item = newData[index];

                item.article_slug = getFormattedDatetime(item.created_at, "YmdHis", false) + "-" + item.article_title.toLowerCase().split(" ").join("-").replace(/[\?\!,]/g, "");
                // item.article_slug = item.article_title.toLowerCase().split(" ").join("-").replace(/[\?\!,]/g, "");
            }

            if(refresh) {
                data = newData;
            }

            return {
                ...state,
                data: {
                    ...data,
                    ...newData
                },
                amount
            };
        case actionTypes.ARTICLE_DETAIL:
            detail = action.payload.data;

            return {
                ...state,
                detail
            }
        default: 
            return state;
    }
};

export default articleReducer;