import React, {Component, Fragment, createRef, useEffect} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './Navbar.scss';
import {Link, Routes, BrowserRouter as Router, Route, NavLink} from "react-router-dom";

import Init from '../../../Init/Init';

import { connect } from 'react-redux';
import metatagActions from '../../../Redux/MetaTags/metatag-action';
import legalServiceActions from '../../../Redux/LegalService/legalservice-action';

const { loadMetatag } = metatagActions;
const { loadLegalServices } = legalServiceActions;

const withParameters = (Component) => {
    function ComponentWithRouter(props) {
        useEffect( () => {
            props.loadMetatag();
            props.loadLegalServices();
        }, []);

        return (
            <Component />
        )
    }

    return ComponentWithRouter
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadMetatag: () => dispatch(loadMetatag()),
        loadLegalServices: () => dispatch(loadLegalServices())
    }
}; 

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: window.location.pathname,
            navigationRef: createRef()
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        
    }

    componentDidUpdate() {
        // this.handleNavClick(this.state.currentPath);
    }

    getCurrentPath(path) {
        this.handleNavClick(path);
    }

    handleNavClick = (path) => {
        let navRef = this.state.navRef.current;
        
        if(navRef !== null) {
            const currentPath = path;
            const elementId = currentPath.replace('#', '');
    
            // const navbar = document.getElementById('navigation-bar');
            const section = document.getElementById(elementId);
            
            window.scroll({
                behavior: 'smooth',
                top: section.offsetTop
                // top: section.offsetTop
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        let state = this.state;
        let logoPath = Init.config.imageLogoAlt;
        let { navList, show } = this.props;
        // let navList = [];

        return (
            <Router>
                <Navbar ref={this.state.navRef} variant='dark' id="navigation-bar" expand="xl" className={`custom-navbar-bg ${show ? '' : 'd-none'}`}>
                    <Navbar.Brand as={Link} to="/">
                        <img className="nav-logo" src={logoPath} alt="Logo" />
                    </Navbar.Brand>
                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="">
                            {
                                navList.map( (item, index) => {
                                    let navLinks = "";

                                    if(item.hasOwnProperty('visibility') && item.visibility) {
                                        navLinks = (
                                            <NavLink key={index} 
                                                    to={item.path} 
                                                    // activeClassName="active" 
                                                    className="nav-link ml-2" 
                                                    onClick={() => this.getCurrentPath(item.path)}> {item.name} </NavLink>
                                        );
                                    }

                                    return navLinks;
                                } )
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {/* Render the first matched path */}
                <Routes>
                    {
                        this.props.navList.map((route, i) => {
                                const ComponentWithParameters = withParameters(route.component);

                                return (
                                    <Route
                                        key={i}
                                        path={route.path}
                                        element={
                                            <ComponentWithParameters
                                                loadMetatag={ () => this.props.loadMetatag() }
                                                loadLegalServices={ () => this.props.loadLegalServices() }
                                            />
                                        }
                                    />
                                )
                            } 
                        )
                    }
                </Routes>
            </Router>
            // <Fragment>
            // </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(NavigationBar);