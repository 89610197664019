import { responseFormat, getFormattedDatetime } from '../Util/Util';
import api from './API';

const basePath = 'article';
const pathList = {
    list: `/`,
    detail: `/:param`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const articleList = async (startId = 0, limit = 10, keyword = '') => {
    try {
        const apiPath = pathList.list;

        const request = {
            // startId,
            search: keyword
        }

        if( startId !== null && startId !== 0 ) {
            request.startId = startId;
        } else {
            request.startId = 0;
        }

        if( limit !== null ) {
            request.limit = limit;
        }

        console.log("Article List", request);

        const response = await api.getCall(apiPath, null, request);

        let returnData;
        let returnArticles;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            returnData = response.data;
            returnArticles = returnData.article;

            for(let index in returnArticles) {
                let item = returnArticles[index];

                item.article_image = `${process.env.REACT_APP_API_ARTICLE_STORAGE}${item.article_image}`;
                item.published_time = getFormattedDatetime(item.created_at, 'd M Y H:i');
                item.published_by = item.fullname;

                returnArticles[index] = item;
            }

            returnData.article = returnArticles;
        }

        return responseFormat(response.errorCode, 'Fetch articles success', returnData);
    } catch(e) {
        return responseFormat(-1, 'Failed to get articles!', {});
    }
}

const articleDetail = async (id = 0) => {
    try {
        const apiPath = pathList.detail;
        const response = await api.getCall(apiPath, id);

        let returnData;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            returnData = response.data;
            
            returnData.article_image = `${process.env.REACT_APP_API_ARTICLE_STORAGE}${returnData.article_image}`;
            returnData.published_time = getFormattedDatetime(returnData.created_at, 'd M Y H:i');
            returnData.published_by = returnData.fullname;
        }

        return responseFormat(0, 'Fetch article success', returnData);
    } catch(e) {
        return responseFormat(-1, 'Failed to get article!', {});
    }
}

const articlesData =  async (id = null, startId = 0, limit = 10, keyword = '', staticData = false) => {
    let returnData = {};

    if(staticData) {
        const baseData = [
            {
                "id": 1,
                "article_image": 'https://placeimg.com/400/225/architecture',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Perusahaan Asuransi Cigna Resmi Lakukan Merger dengan Chubb Life Indonesia",
                "short_description": `Perusahaan Asuransi Cigna Resmi Lakukan Merger dengan Chubb Life Indonesia “UU PT sejatinya mengatur beberapa tahapan yang harus dilakukan perseroan dalam melakukan penggabungan.”`,
                "article_content": `
                    Baru-baru ini, perusahaan asuransi PT Cigna melakukan aksi korporasi berupa merger dengan PT Chubb life Insurance Indonesia. Dilansir Bisnis, hal ini akan efektif dilakukan setelah mendapatkan persetujuan dari Otoritas Jasa Keuangan (OJK) atas penggabungan dan Kementerian Hukum dan hak asasi manusia atas perubahan anggaran dasar perusahaan yang menerima penggabungan telah masing-masing diperoleh perusahaan. 
                    
                    “Direksi PT Chubb Life Insurance Indonesia [PTCLII] dan PT Asuransi Cigna [PTAC] mengumumkan rencana penggabungan antar PTCLII dan PTAC, di mana PTCLII menjadi entitas yang menerima penggabungan,” ungkap direksi PT Asuransi Cigna dalam pengumuman, dikutip pada Rabu (21/12/2022). 
                    
                    Menurut Undang-Undang Nomor 40 Tahun 2007 tentang Perseroan Terbatas (UU PT),  merger atau penggabungan merupakan perbuatan hukum yang dilakukan oleh satu Perseroan atau lebih untuk menggabungkan diri dengan Perseroan lain yang telah ada yang mengakibatkan aktiva dan pasiva dari Perseroan yang menggabungkan diri beralih karena hukum kepada Perseroan yang menerima penggabungan. Dalam aksi penggabungan, status badan hukum Perseroan yang menggabungkan diri ini berakhir karena hukum.
                    
                    UU PT sejatinya mengatur beberapa tahapan yang harus dilakukan perseroan dalam melakukan penggabungan. Beberapa tahapan yang perlu dilakukan tersebut antara lain: pertama, Direksi Perseroan yang akan melakukan Penggabungan wajib mengumumkan ringkasan rancangan paling sedikit dalam 1 (satu) Surat Kabar dan mengumumkan secara tertulis kepada karyawan dari Perseroan yang akan melakukan Penggabungan dalam jangka waktu paling lambat 30 (tiga puluh) hari sebelum pemanggilan RUPS.
                    
                    Pengumuman memuat juga pemberitahuan bahwa pihak yang berkepentingan dapat memperoleh rancangan Penggabungan di kantor Perseroan terhitung sejak tanggal pengumuman sampai tanggal RUPS diselenggarakan. 
                    
                    Kedua, kreditor dapat mengajukan keberatan mengenai Penggabungan kepada Perseroan dalam jangka waktu paling lambat 14 (empat belas) hari setelah pengumuman. Apabila dalam jangka waktu 14 (empat belas) hari setelah pengumuman kreditor tidak mengajukan keberatan, kreditor dianggap menyetujui Penggabungan. 
                    
                    Adapun apabila keberatan yang diajukan oleh kreditor sampai dengan tanggal diselenggarakan RUPS tidak dapat diselesaikan oleh Direksi, keberatan tersebut harus disampaikan dalam RUPS agar mendapatkan penyelesaian. Selama penyelesaian belum tercapai, maka Penggabungan tidak dapat dilaksanakan. 
                    
                    Nantinya, keputusan RUPS mengenai Penggabungan diambil sebagaimana dengan ketentuan RUPS Pasal 87 ayat (1) dan Pasal 89 UUPT. Rancangan Penggabungan yang telah disetujui RUPS dituangkan ke dalam Akta Penggabungan yang dibuat di hadapan notaris dalam bahasa Indonesia. 
                    
                    Salinan Akta Penggabungan Perseroan dilampirkan kepada Menteri sebagai pemberitahuan kepada Menteri mengenai perubahan anggaran dasar dan untuk mendapatkan persetujuan Menteri terkait dengan Penggabungan Perseroan. Dalam hal Penggabungan Perseroan tidak disertai perubahan anggaran dasar, salinan Akta Penggabungan harus disampaikan kepada Menteri untuk dicatat dalam daftar Perseroan. 
                    
                    Lebih lanjut, Pasal 133 UU PT mengatur bahwa Direksi Perseroan yang melakukan Penggabungan wajib mengumumkan hasil Penggabungan dalam 1 (satu) Surat Kabar atau lebih dalam jangka waktu paling lambat 30 (tiga puluh) hari terhitung sejak tanggal berlakunya Penggabungan. Tahapan inilah yang nantinya akan dilalui PT Cigna dengan PT Chubb life Insurance Indonesia pasca persetujuan menteri atas penggabungan.
                `
            },
            {
                "id": 2,
                "article_image": 'https://placeimg.com/400/225/tech',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 3,
                "article_image": 'https://placeimg.com/400/225/people',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 4,
                "article_image": 'https://placeimg.com/400/225/nature',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 5,
                "article_image": 'https://placeimg.com/400/225/animals',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 6,
                "article_image": 'https://placeimg.com/400/225/architecture',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 7,
                "article_image": 'https://placeimg.com/400/225/tech',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 8,
                "article_image": 'https://placeimg.com/400/225/people',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 9,
                "article_image": 'https://placeimg.com/400/225/nature',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
            {
                "id": 10,
                "article_image": 'https://placeimg.com/400/225/animals',
                'published_by': 'Leo',
                "published_time": 'Senin, 26 Des 2022 20:00 WIB',
                "article_title": "Maling digebukin warga",
                "short_description": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja.",
                "article_content": "Seorang pria bertato macan, kepergok mencuri motor dan digebukin warga.\nTapi sayang macannya diam saja."
            },
        ];
    
        if(id !== null) {
            returnData = baseData.find(item => item.id === id);
        } else {
            returnData = baseData;
        }
    } else {
        if(id !== null) {
            returnData = await articleDetail(id);
        } else {
            console.log("Articles Data", {
                startId,
                limit
            });
            returnData = await articleList(startId, limit, keyword);
        }
    }


    return returnData;
}



export default articlesData;