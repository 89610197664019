import Homepage from '../Container/Homepage/Homepage';

import ErrorPage from '../Container/ErrorPage/ErrorPage';
import ComingSoon from '../Component/PagesComponent/ComingSoon/ComingSoon';
import ArticleDetailPage from '../Container/ArticleDetailPage/ArticleDetailPage';
import ArticlePage from '../Container/ArticlePage/ArticlePage';
import PrivacyPage from '../Container/PrivacyPage/PrivacyPage';
import TermsPage from '../Container/TermsPage/TermsPage';
import BusinessEntityPage from '../Container/BusinessEntityPage/BusinessEntityPage';
import CheckoutPage from '../Container/CheckoutPage/CheckoutPage';
import SummaryPage from '../Container/SummaryPage/SummaryPage';
import FAQPage from '../Container/FAQPage/FAQPage';

import WhatsappRedirection from '../Container/WhatsappRedirection/WhatsappRedirection';
import TransactionResultRedirection from '../Container/TransactionResultRedirection/TransactionResultRedirection';
import B2BPackagePage from '../Container/B2BPackagePage/B2BPackagePage';

/**
 * 1. Banner / tagline
 * 2. Landack. itu apa sih (visi misi) - X
 * 3. Bisnis kita apa, produk kita apa
 * 4. Keunggulan kita apa
 * 5. Pricing
 * 6. Accesibility
 * 7. FAQ
 * 8. Artikel
 * 9. How to contact us
 * 10. Pengenalan founder (optional) - X
 */
const Routes = () => {
    return [
        {
            path: "/",
            name: "Beranda",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/#about-us",
            name: "Tentang Kami",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/#advantage",
            name: "Kenapa Landack.?",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/#individual-pricing",
            name: "Layanan",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/pendirian-badan-usaha",
            name: "Pendirian Badan Usaha",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: BusinessEntityPage
        },
        {
            path: "/#b2b-section",
            name: "Landack. Bisnis",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/#clients",
            name: "Klien & Partners",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/faq",
            name: "FAQ",
            visibility: true,
            showNav: true,
            showFooter: false,
            component: FAQPage
        },
        {
            path: "/#article",
            name: "Artikel",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/article/:title",
            name: "Article Detail",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ArticleDetailPage
        },
        {
            path: "/article/",
            name: "Article List",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ArticlePage
        },
        {
            path: "/privacy-policy",
            name: "Privacy Policy",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: PrivacyPage
        },
        {
            path: "/terms-and-condition",
            name: "Terms & Condition",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: TermsPage
        },
        {
            path: "/coming-soon",
            name: "Coming Soon",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ComingSoon
        },
        {
            path: "/consult-now",
            name: "Consult Now",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: WhatsappRedirection
        },
        {
            path: "/pembayaran-berhasil/:transactionCode",
            name: "Pembayaran Berhasil",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: TransactionResultRedirection
        },
        {
            path: "/order/:serviceType/:serviceCode",
            name: "Order",
            visibility: false,
            showNav: true,
            showFooter: false,
            component: CheckoutPage
        },
        {
            path: "/order/summary",
            name: "Summary",
            visibility: false,
            showNav: true,
            showFooter: false,
            component: SummaryPage
        },
        {
            path: "/landack-bisnis",
            name: "Landack. Bisnis",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: B2BPackagePage
        },
        {
            path: "*",
            name: "Default",
            visibility: false,
            component: ErrorPage
        }
    ];
}

export default Routes;