import React, { useState, useEffect } from 'react';

import './BusinessEntityPage.scss';
import { businessEntityList } from '../../Data/BusinessEntity';

import BusinessEntityList from '../../Component/PagesComponent/BusinessEntityList/BusinessEntityList';
import BusinessEntityFooter from '../../Component/PagesComponent/BusinessEntityFooter/BusinessEntityFooter';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { businessEntityLink, buildWaLink, numberFormatting, objectIsEmpty, lcFirst } from '../../Util/Util';
import StarterKit from '../../Component/PagesComponent/StarterKit/StarterKit';

const BusinessEntityPage = (props) => {
    const [ service, setService ] = useState({});

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        (async () => {
            const response = await businessEntityList();

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = [];

                for(let index in response.data.businessEntity) {
                    let item = response.data.businessEntity[index];

                    data.push({
                        name: item.business_entity_name,
                        priceList: item.prices.map((value) => {
                            // if(process.env.REACT_APP_ENV === 'production') {
                            //     value.waLink = buildWaLink('melakukan', `"${item.business_entity_name}"`);
                            // } else {
                            //     value.waLink = businessEntityLink(value.business_entity_code);
                            // }
                            value.waLink = businessEntityLink(item.business_entity_code);

                            return value;
                        })
                    });
                }
                
                setService({
                    data,
                    addOns: {
                        ...response.data.addOns
                    }
                });
            }
        })();
    }, [])

    return (
        <div className="business-entity-page">
            <StarterKit />
            <div className='business-entity-section'>
                <h1 className='business-entity-page-title'>
                    Pendirian Badan Usaha
                </h1>
                {
                    !objectIsEmpty(service) ? (
                        <BusinessEntityList serviceData={service} />
                    ) : (
                        <div className="business-entity-loading-container">
                            <LoadingPage />
                        </div>
                    )
                }
                <BusinessEntityFooter />
            </div>
        </div>
    );
}

export default BusinessEntityPage;