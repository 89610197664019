import React, { useState, useEffect } from 'react';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';
import { membershipList } from '../../../Data/Membership';

import './Membership.scss';

import { connect } from 'react-redux';
import { buildWaLink, numberFormatting } from '../../../Util/Util';

import Init from '../../../Init/Init';

const { FontAwesomeIcon, config } = Init;
const { yes } = config; 

const mapStateToProps = (state) => {
    return {
        legalServices: state.legalService.data
    }
}

const Membership = (props) => {
    const [ membership, setMembership ] = useState([]);
    const [ legalService, setLegalService ] = useState({});

    const descriptions = {
        mascot: yes,
        text: `Bisnis sobat aman-aman saja, tapi takut kenapa-napa? <br /> Jaga-jaga bareng Landack.!`
    };

    const smallNotes = [
        `Tidak termasuk <i>corporate action<i/>`,
        "Kalau ada perpindahan dari Landack. Membership ke Landack. Full Service maka sisa dari membershipnya dikembalikan secara pro rata"
    ]

    useEffect( () => {
        setLegalService(props.legalServices);
    }, [ props.legalServices ]);

    useEffect( () => {
        const locationHash = window.location.hash;

        if( locationHash ) {
            document.querySelector(`${locationHash}`).scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    useEffect( () => {
        ( async () => {
            const response = await membershipList();

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = [];

                for( let index in response.data ) {
                    let item = response.data[index];
                    let pricing = {};

                    for( let i in item.pricing ) {
                        let pricingItem = item.pricing[i];

                        pricing[pricingItem.service_code] = pricingItem.price;
                    }

                    item.pricing = pricing;
                    data.push(item);
                }

                setMembership(data);
            }
        } )();
    }, [] );

    return (
        <div id="membership" className='membership'>
            <h2 className='membership-title'>
                Landack. Membership
            </h2>
            <div className="membership-content">
                {
                    membership.length > 0 ? (
                        <div className="membership-table">
                            <table className='table table-bordered table-hover table-advanced'>
                                <thead>
                                    <tr>
                                        <th>Layanan</th>
                                        <th>Harga Normal</th>
                                        {
                                            membership.map( (item, index) => {
                                                return (
                                                    <th key={index}>Harga { item.membership_name }</th>
                                                )
                                            } )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        legalService.map( (item, index) => {
                                            // if ( item.service_code === 'MRK' ) return '';
                                            if ( [
                                                'DK',
                                                'RDB',
                                                'PPB',
                                                'NDAB',
                                                'SSK',
                                                'JSK',
                                                'LO',
                                                'MRK'
                                            ].includes(item.service_code) ) return '';

                                            return (
                                                <tr key={index}>
                                                    <td className='service-name'>{ item.service_name }</td>
                                                    <td>{ numberFormatting(item.price) }</td>
                                                    {
                                                        membership.map( (member, memberIndex) => {
                                                            if( !member.pricing.hasOwnProperty(item.service_code) ) return (
                                                                <td>-</td>
                                                            );

                                                            let memberPrice = member.pricing[item.service_code] > 0 ? numberFormatting(member.pricing[item.service_code]) : 'Free';

                                                            return (
                                                                <td key={memberIndex}>{ memberPrice }</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        } )
                                    }
                                </tbody>
                            </table>

                            <div className="membership-table-small-notes">
                                Keterangan:
                                <ul>
                                    {
                                        smallNotes.map( ( item, index ) => {
                                            return (
                                                <li key={ index } dangerouslySetInnerHTML={{__html: item}}></li>
                                            )
                                        } )
                                    }
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <div className="membership-loading-section">
                            <LoadingPage />
                        </div>
                    )
                }
                <div className="membership-description">
                    <div className="description-mascot">
                        <img src={ descriptions.mascot } alt="" />
                    </div>
                    <div className="description-text" dangerouslySetInnerHTML={{__html: descriptions.text}}>
                        
                    </div>
                    <div className="description-btn-container">
                        <a href={  buildWaLink("mau berlangganan", "Landack. Membership") } className='btn-membership'> Gabung Sekarang </a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default connect(mapStateToProps)(Membership);