import React, {Component, Fragment} from 'react';
import './PrivacyPage.scss';

const Paragraph = (props) => {
    return (
        props.data.map( (item, index) => {
            return (
                <p key={index} className="item-paragraph" dangerouslySetInnerHTML={{ __html: item }}>           
                </p>
            );
        } )
    );
}

const List = (props) => {
    console.log({
        title: props.data.title,
        list: props.data.list
    });

    return (
        <Fragment>
            <p className="item-lists-title">
                {props.data.title}
            </p>
            <ol className='item-lists'>
                {
                    props.data.list.map( (item, index) => {
                        return(
                            typeof item === 'object' ? (
                                item.map( (v, i) => {
                                    switch(v.type) {
                                        case 'paragraph':
                                            return (
                                                <Paragraph key={i} data={v.content} />
                                            );
                                        case 'list':
                                            return (
                                                <List key={i} data={v.content} />
                                            );
                                        default:
                                            return '';
                                    }
                                } )
                            ) : (
                                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                            )
                        );
                    })
                }
            </ol>
        </Fragment>
    );
};

const Table = (props) => {
    const { useNo, columns, data } = props.data;

    return (
        <table className='item-table'>
            <thead>
                <tr>
                    { useNo && (
                        <th>
                            No
                        </th>
                    ) }
                    { columns.map( (item, index) => {
                        return (
                            <th key={index}>
                                { item.name }
                            </th>
                        )
                    } ) }
                </tr>
            </thead>
            <tbody>
                {
                    data.map( (item, index) => {
                        return item.hasOwnProperty('isHeader') && item.isHeader ? (
                            <tr>
                                <th align={"center"} colSpan={ item.config.colSpan + ( useNo ? 1 : 0 ) }>
                                    { item.config.label }
                                </th>
                            </tr>
                        ) : (
                            <tr>
                                {
                                    useNo && (
                                        <td className="index-no" align={'center'}> { index } </td>
                                    )
                                }
                                {
                                    columns.map( (value, key) => {
                                        return (
                                            <td> { item.hasOwnProperty(value.key) ? item[value.key] : '-' } </td>
                                         )
                                    } )
                                }
                            </tr>
                        )
                    } )
                }
            </tbody>
        </table>
    );
};

class PrivacyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: {
                title: "Lingkup Kebijakan Privasi",
                description: [
                    `Kebijakan privasi ini bertujuan untuk menjaga segala bentuk informasi terkait identitas, aktivitas dan segala bentuk komunikasi antara Penyedia Jasa, Pengguna Jasa dan Landack.`,
                    `Berlaku sejak 3 Mei 2023`,
                    `Karena itu, Landack menghargai dan melindungi data dan informasi pribadi Anda. Anda dapat memilih data dan informasi pribadi apa yang akan Anda bagikan. Kebijakan privasi ini menetapkan apa saja yang dilakukan oleh Landack dalam mengumpulkan dan menggunakan data dan informasi pribadi Anda serta langkah – langkah lain yang berkaitan dengan perlindungan privasi. Jika tidak ditentukan lain, istilah dalam Kebijakan Privasi ini merujuk istilah yang dipergunakan dalam Syarat dan Ketentuan.`,
                ]
            },
            list: [
                /*
                    Format: 
                    {
                        title: "",
                        description: [
                            {
                                type: "list",
                                content: { title: "", list: "" }
                            },
                            {
                                type: "paragraph",
                                content: [text]
                            },
                        ]
                    }
                */

                // Koleksi dan Penggunaan Data Pribadi
                {
                    title: "Koleksi dan Penggunaan Data Pribadi",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                    "Untuk dapat menggunakan dan mengakses Layanan Landack, Anda diharuskan untuk mengisi dan/atau melengkapi data pribadi. Seluruh data diri dan/atau informasi tentang diri Anda yang di input pada sistem Landack secara sistem akan terdokumentasi dan menjadi dokumen rahasia.",
                                    "Data Pribadi yang dikumpulkan dari Anda sebagai Pengguna Jasa di Platform Landack adalah sebagai berikut:",
                                    [
                                        {
                                            type: "list",
                                            content: {
                                                list: [
                                                    `Data Pribadi yang diberikan saat Anda membuat Perjanjian atau memberikan dokumen atau informasi lainnya sehubungan dengan Layanan yang dipilih oleh Anda di dalam Platform Landack;`,
                                                    `Data Pribadi yang diberikan kepada Landack saat berinteraksi, melalui pertemuan tatap muka maupun daring, platform media sosial dan surel; dan`,
                                                    `Data Pribadi yang terekam di sistem Landack pada saat Anda menggunakan Platform Landack.`
                                                ]
                                            }
                                        },
                                    ],
                                    "Landack dapat menggunakan Data Pribadi tersebut untuk tujuan sebagai berikut maupun tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku:",
                                    [
                                        {
                                            type: "list",
                                            content: {
                                                list: [
                                                    "Jika Anda adalah Pengguna Jasa, Landack dapat menggunakan Data Pribadi Anda:",
                                                    [
                                                        {
                                                            type: "list",
                                                            content: {
                                                                list: [
                                                                    `Mengizinkan Anda untuk mengakses dan menggunakan Layanan Landack;`,
                                                                    `Untuk mengidentifikasi dan mendaftarkan Anda sebagai Pengguna Jasa dan untuk mengadministrasikan, memverifikasi, menonaktifkan, atau mengelola Akun Anda di Platform Landack;`, 
                                                                    `Untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan Landack diperlukan sebelum Penyedia Jasa memberikan Layanan kepada Anda atau sebelum Landack mendaftarkan Anda sebagai Pengguna Jasa;`,
                                                                    `Untuk memungkinkan Penyedia Jasa untuk menyediakan Layanan yang Anda minta;`,
                                                                    `Untuk memproses maupun memfasilitasi pembayaran Anda;`,
                                                                    `Untuk memberitahu Anda atas transaksi atau aktivitas yang terjadi dalam Platform Landack atau sistem lain yang terhubung dengan Platform Landack;`, 
                                                                    `Untuk mengolah dan menanggapi pertanyaan, komentar dan masukan yang diterima dari Anda;`, 
                                                                    `Mengirimkan kepada Anda surat elektronik, pesan singkat, atau bentuk komunikasi lainnya yang berkaitan dengan penggunaan Platform Landack;`,
                                                                    `Untuk memverifikasi identitas, data pribadi, menghubungkan Anda dengan Penyedia Jasa untuk Layanan yang Anda minta dan untuk tujuan lainnya sehubungan dengan Layanan Landack; dan/atau`,
                                                                    `Menambahkan Anda ke daftar pengiriman pesan, mengirimkan Anda materi pemasaran dan promosi yang menarik. Anda dapat memutuskan untuk berhenti berlangganan dari daftar pengiriman pesan dan menghentikan penerimaan materi pemasaran dan promosi setiap saat melalui tautan dalam komunikasi tersebut atau Anda dapat mengirimkan surel kepada Landack di <a href='mailto:info@landack.id'>info@landack.id</a>.`
                                                                ]
                                                            }
                                                        },
                                                    ],
                                                    "Jika Anda adalah Penyedia Jasa, Landack dapat menggunakan Data Pribadi Anda:",
                                                    [
                                                        {
                                                            type: "list",
                                                            content: {
                                                                list: [
                                                                    `Untuk mengidentifikasi dan mendaftarkan Anda sebagai Penyedia Jasa dan untuk mengadministrasi, mengelola dan/atau memverifikasi Akun Anda di Platform Landack; `,
                                                                    `Untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut kebijakan Landack sepenuhnya perlu dilakukan sebelum Landack dapat mendaftarkan Anda sebagai Penyedia Jasa dalam Platform Landack; `, 
                                                                    `Untuk memungkinkan Anda memberikan Layanan kepada pengguna;`,
                                                                    `Untuk memberikan Anda pemberitahuan dan pembaharuan pada Platform Landack atau perubahan cara penyediaan Layanan;`,
                                                                    `Untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Platform Landack untuk memenuhi kebutuhan dan preferensi Anda sebagai Penyedia Jasa;`,
                                                                    `Untuk memantau dan menganalisa aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan daya tanggap Penyedia Jasa untuk berbagai Layanan yang tersedia pada Platform Landack; dan/atau`,
                                                                    `Untuk menawarkan atau menyediakan Layanan dari afiliasi atau mitra Landack.`
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                ]
                                            }
                                        },
                                    ]
                                ]
                            }
                        },
                    ]
                },
                // Koleksi Informasi Lain
                {
                    title: "Koleksi Informasi Lain",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                    "Setiap Anda mengunjungi Platform Landack, data dan informasi Anda yang ada di mesin pencari akan terekam secara otomatis. Data tersebut mengidentifikasi Anda sebagai individu dan mencakup:",
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                list: [
                                                    "Alamat IP Komputer",
                                                    "Jenis Browser",
                                                    "Halaman yang Anda kunjungi di Platform Landack; dan",
                                                    "Waktu yang dihabiskan di laman tersebut, hal dan informasi yang Anda cari di Platform Landack, waktu dan tanggal pengaksesan, dan statistik lainnya."
                                                ]
                                            }
                                        }
                                    ],
                                    "Informasi tersebut dikumpulkan untuk menganalisa dan mengevaluasi dengan tujuan membantu Landack memperbaiki /meningkatkan Platform Landack dan layanannya.",
                                    "Untuk kepentingan peningkatan Layanan, Platform Landack menggunakan Cookies dan Google Analytics. Landack juga dapat menggunakan pemasaran ulang dengan Google Analytics untuk mengiklankan secara online. Vendor pihak ketiga, termasuk Google, akan menampilkan iklan Landack di situs internet lainnya. Landack dan vendor pihak ketiga, termasuk Google menggunakan Cookies pihak pertama (seperti Google Analytics Cookie) dan Cookies pihak ketiga (seperti DoubleClick Cookie) untuk memberitahukan, mengoptimalkan, dan menampilkan iklan sesuai dengan kunjungan Anda sebelumnya ke Platform Landack, serta melaporkan bagaimana tayangan iklan, kegunaan lain dari layanan iklan, dan interaksi dengan tayangan iklan dan layanan iklan yang terkait untuk mengunjungi Platform Landack."
                                ]
                            }
                        }
                    ] 
                },
                // Tautan ke Platform Lain
                {
                    title: "Tautan ke Platform Lain",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                        `Landack dapat menyediakan tautan ke platform-platform lain yang berada diluar pengawasan Landack, termasuk tautan dari Penyedia Jasa;`,
                                        `Landack tidak bertanggung jawab atas praktik privasi, produk, layanan, atau konten lain dari platform atau halaman diluar pengawasan Landack dan juga tidak bertanggung jawab atas isi dan atau kebijakan dari platform pihak ketiga tersebut.`
                                ]
                            }
                        }
                    ] 
                },
                // Pengungkapan Informasi
                {
                    title: "Pengungkapan Informasi",
                    description: [
                        {
                            type: 'list',
                            content: {
                                title: "Landack tidak akan memindahtangankan dan/atau menyebarluaskan data pribadi yang telah Anda berikan, kecuali diwajibkan oleh peraturan perundang-undangan yang berlaku atau atas izin Anda. Akan tetapi, Landack dapat mengungkapkan atau memindahkan data pribadi atau informasi Anda kepada afiliasi dan/atau pihak ketiga yang secara resmi terlibat untuk membantu Landack dalam mengembangkan Layanan Landack serta untuk tujuan lain sepanjang diperbolehkan oleh peraturan perundang-undangan yang berlaku seperti:",
                                list: [
                                    `Untuk memungkinkan Penyedia Jasa untuk melakukan atau memberikan Layanan kepada Anda;`,
                                    `Untuk memungkinkan Pengguna Jasa untuk meminta atau menerima Layanan dari Penyedia Jasa;`,
                                    `Jika disyaratkan atau diotorisasikan oleh peraturan perundang-undangan yang berlaku (termasuk namun tidak terbatas pada menanggapi pertanyaan terkait regulasi, penyelidikan atau pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan pelaporan berdasarkan undang-undang), untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang berlaku;`,
                                    `Jika diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh pemerintah yang berwenang, untuk tujuan sebagaimana disebutkan dalam kebijakan pemerintah, peraturan atau peraturan perundang-undangan yang berlaku;`,
                                    `Untuk kepentingan investigasi internal atas dugaan tindak pidana atau pelanggaran peraturan atau kebijakan Landack;
                                    Jika terdapat proses hukum dalam bentuk apapun antara Anda dengan Landack, atau antara Anda dengan pihak lain, sehubungan dengan, atau terkait dengan Layanan dalam Platform Landack, untuk keperluan proses hukum tersebut;`,
                                    `Sehubungan dengan segala proses verifikasi yang Landack dan/atau pihak ketiga perlukan sebelum Penyedia Jasa memberikan Layanan kepada Anda atau Landack mendaftarkan Anda sebagai Pengguna Jasa;`,
                                    `Sehubungan dengan penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua atau sebagian dari bisnis Landack oleh atau ke perusahaan lain, untuk keperluan transaksi tersebut (bahkan jika kemudian transaksi tidak dilanjutkan);`
                                ]
                            }
                        }
                    ] 
                },
                // Perlindungan Informasi
                {
                    title: "Perlindungan Informasi",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                    `Landack berkomitmen untuk mengambil langkah – langkah untuk memastikan bahwa data atau informasi pribadi yang Landack kumpulkan bebas dari kemungkinan kehilangan, penyalahgunaan, pelecehan, modifikasi, atau penggunaan, akses, pengungkapan, modifikasi, perubahan, dan kerusakan yang tidak sah atau disengaja;`,
                                    `Landack memastikan bahwa semua data pribadi terkumpul dan disimpan secara aman. Landack melindungi informasi pribadi dengan: membatasi akses ke data pribadi; menjaga dan mengimplementasikan sistem untuk melindungi akses komputer yang tidak dibenarkan;`, 
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: `dan menghancurkan dan/atau menghapus informasi pribadi Anda saat tidak lagi dibutuhkan atau dimintakan penghapusannya oleh Anda;
                                                Kewajiban untuk menjaga kerahasiaan data pribadi tidak akan atau tidak lagi berlaku atas data pribadi yang:`,
                                                list: [
                                                    `Telah diketahui oleh publik pada saat diungkapkan, atau yang telah diketahui oleh publik setelahnya dengan cara lain selain melalui tindakan atau kelalaian Landack;`,
                                                    `Landack peroleh secara layak dari pihak ketiga tanpa adanya kewajiban untuk menjaga kerahasiaan data pribadi tersebut;`,
                                                    `Pengungkapannya telah diizinkan oleh Anda; dan/atau`,
                                                    `Harus diungkapkan berdasarkan ketentuan peraturan perundang-undangan yang berlaku, putusan pengadilan, atau institusi pemerintahan yang berwenang lainnya.`
                                                ]
                                            }
                                        }
                                    ]
                                ]
                            }
                        }
                    ] 
                },
                // Penghapusan Informasi
                {
                    title: "Penghapusan Informasi",
                    description: [
                        {
                            type: 'list',
                            content: {
                                list: [
                                    `Landack akan menyimpan Data Pribadi Anda selama diperlukan untuk pelaksanaan perjanjian antara Anda dan Landack dan/atau untuk mematuhi kewajiban hukum Landack. Jika Anda ingin menghapus Data Pribadi Anda dalam Landack, Anda dapat meminta Landack untuk menghapus Data Pribadi Anda dan menutup Akun Anda secara tertulis; dan`,
                                    `Perlu diketahui bahwa setelah permintaan penghapusan Data Pribadi Anda dalam Landack, Landack dapat menyimpan beberapa Data Pribadi Anda sebagaimana diperlukan dalam rangka meningkatkan keamanan serta kepentingan hukum dan/atau akuntansi Landack sesuai dengan jangka waktu yang disyaratkan oleh perundang-undangan yang berlaku.`
                                ]
                            }
                        }
                    ] 
                },
                // Hukum Yang Berlaku
                {
                    title: "Hukum Yang Berlaku",
                    description: [
                        {
                            type: 'paragraph',
                            content: [
                                `Kebijakan Privasi ini diatur berdasarkan hukum Negara Republik Indonesia dan Anda diwajibkan untuk tunduk dan patuh kepada semua aturan yang berlaku di Negara Republik Indonesia.`
                            ]
                        }
                    ] 
                },
                // Ketentuan Lainnya
                {
                    title: "Ketentuan Lainnya",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                    `Jika Anda ingin mengakses atau melakukan koreksi apapun terhadap data pribadi yang telah dikumpulkan oleh Landack, atau jika Anda memiliki pertanyaan yang berkaitan dengan data pribadi, silakan hubungi Landack melalui surel di <a href="mailto:info@landack.id">info@landack.id</a>.`,
                                    `Landack dapat merevisi Kebijakan Privasi ini setiap saat jika diperlukan. Anda akan diberitahukan akan perubahan tersebut. Dengan mengakses atau melanjutkan penggunaan layanan Landack setelah perubahan Kebijakan Privasi, Anda setuju untuk terikat oleh Kebijakan Privasi tersebut, termasuk perubahan yang tercantum di dalamnya.`
                                ]
                            }
                        }
                    ] 
                },
            ]
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div className="privacy-policy">
                <div className="privacy-policy-intro">
                    <h1> { this.state.header.title } </h1>
                    {
                        this.state.header.description.map( (item, index) => {
                            return (
                                <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                                </p>
                            );
                        })
                    }
                </div>
                <div className="privacy-policy-content">
                    <ol>
                        {
                            this.state.list.map( (item, index) => {
                                return(
                                    <div key={index} className="privacy-policy-content-item">
                                        <li> {item.title} </li>

                                        {
                                            item.description.map( (v, i) => {
                                                switch(v.type) {
                                                    case 'paragraph':
                                                        return (
                                                            <Paragraph key={i} data={v.content} />
                                                        );
                                                    case 'list':
                                                        return (
                                                            <List key={i} data={v.content} />
                                                        );
                                                    default:
                                                        return '';
                                                }
                                            } )
                                        }

                                    </div>
                                );
                            } )
                        }
                    </ol>
                </div>
            </div>
        );
    }
}

export default PrivacyPage;
