import React, { useState, useEffect, useRef} from 'react';
import {Col, FormLabel, FormGroup, FormControl, Form, Modal, Button, Toast} from 'react-bootstrap';

import Init from '../../Init/Init';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';
import { buildWaLink, numberFormatting, objectIsEmpty, lcFirst } from '../../Util/Util';

import { authPayment, capturePayment } from '../../Data/Transaction';

import { useNavigate } from 'react-router-dom';

import './SummaryPage.scss';

const SummaryPage = (props) => {
    const { FontAwesomeIcon, constants } = Init;
    const navigate = useNavigate();

    const [ summary, setSummary ] = useState({
        serviceType: 0,
        // serviceCode: 'KH',
        // serviceTitle: 'Konsultasi Hukum',
        // serviceIconPath: "https://devapi.landack.id/assets/legal-service/KH.png",
        // serviceDesc: "Konsultasi aja sama Landack.",
        // totalPrice: 100000,
        // phoneNo: "087889532398"
        serviceCode: '',
        serviceTitle: '',
        serviceIconPath: "",
        serviceDesc: "",
        totalPrice: 0,
        phoneNo: ""
    });
    const pinRef = useRef('');

    const [ showModal, setShowModal ] = useState(false);
    const [ toast, setToast ] = useState({
        show: false,
        title: '',
        text: '',
        success: false
    });

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const closeToast = () => setToast({
        ...toast,
        show: false
    });

    const displayToast = (isSuccess, title, message) => {
        setToast({
            title,
            text: message,
            show: true,
            success: isSuccess
        });
    };

    const numericOnly = (event) => {
        let input = event.nativeEvent.data;
        
        if(event.nativeEvent.data !== null && isNaN(parseInt(input))) {
            pinRef.current.value = event.target.value.replace(input, '');
        }
    }

    const checkout = () => {
        if(pinRef.current.value === '') {
            // Failed
        }

        (async() => {
            const response = await capturePayment(
                summary.transactionCode,
                summary.token,
                summary.userPubId,
                summary.paymentMethodPubId,
                pinRef.current.value
            );
            let toastData = {};
            
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                toastData = {
                    title: 'Success',
                    text: response.message
                };

                setTimeout( () => {
                    navigate('/', {
                        replace: true
                    });
                }, 10000);
            } else {
                toastData = {
                    title: 'Failed',
                    text: 'Failed to confirm payment!'
                };
            }

            displayToast(
                response.hasOwnProperty('errorCode') && response.errorCode === 0,
                toastData.title,
                toastData.text
            );

        })();
    };

    useEffect(() => {
        if(toast.show) {
            handleClose();
        }
    }, [toast.show]);

    useEffect(() => {
        // Rebuild obj from query string
        const queryString = window.location.search.replace('?', '').replace('?', '&').split('&');

        if(queryString.length !== 0) {
            const query = queryString.reduce((newObj, item) => {
                let entry = item.split('=');
                let key = entry[0];
                let value = entry[1];
    
                newObj[key] = value;
    
                return newObj;
            }, {});
    
            // Call API to create cashbac payment
            (async() => {
                const response = await authPayment(
                    query.transactionCode,
                    query.token,
                    query.userPubId,
                    query.paymentMethodPubId,
                    query.email
                );

                let serviceData = {};

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    let serviceType = response.data.service_type;
                    let serviceCode = response.data.service_code;

                    serviceData.serviceTitle = response.data.service_title;
                    serviceData.serviceCode = serviceCode;
                    serviceData.serviceType = serviceType;
                    serviceData.totalPrice = response.data.total_price;
                    serviceData.phoneNo = response.data.client_name;
                    serviceData.transactionCode = query.transactionCode;
                    serviceData.userPubId = response.data.userPubId;
                    serviceData.paymentMethodPubId = response.data.paymentMethodPubId;
                    serviceData.token = response.data.token;
                    
                    switch(serviceType) {
                        case constants.serviceType.legalService:
                            serviceData.serviceIconPath = `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${response.data.icon_path}`;
                            serviceData.serviceDesc = response.data.description;
                            
                            break;
                        case constants.serviceType.package:
                            serviceData.serviceDesc = response.data.package_service.map( (item, index) => {
                                let frequencyPortion = item.frequency > 1 ? `${ item.frequency} x ${item.portion} ${item.unit}` : item.portion + 'x';
                                frequencyPortion = item.frequency === 0 ? 'Tanpa Batas' : frequencyPortion;
                                
                                return `${item.service_name} ${ frequencyPortion }`;
                            } ).reduce( (allStr, str) => {
                                return `${allStr}${str}<br /> `;
                            }, '');
                            serviceData.serviceIconPath = `${process.env.REACT_APP_API_PACKAGE_STORAGE}${response.data.icon_path}`;
                            
                            break;
                        case constants.serviceType.businessEntity:
                            serviceData.serviceIconPath = `${process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE}${response.data.icon_path}`;
        
                            break;
                        default:
                            break;
                    } 

                    setSummary(serviceData);
                }
            })();
        }
        
    }, []);

    return summary.serviceType !== 0 ? (
        <>
            <Toast className='result-toast' show={toast.show} onClose={closeToast}>
                <Toast.Header>
                    <strong className={`me-auto ${toast.success ? 'text-success' : 'text-danger'}`}> { toast.title } </strong>
                </Toast.Header>
                <Toast.Body>
                    { toast.text }
                </Toast.Body>
            </Toast>
            <Modal size={'lg'} className="input-pin-modal" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Checkout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel>
                            Insert PIN:
                        </FormLabel>
                        <FormControl
                            name={'cashbacPin'}
                            as={'input'}
                            type={'password'} 
                            ref={pinRef} 
                            required={true}
                            placeholder={'E.g.: 123456'}
                            onChange={ (event) => numericOnly(event) }
                            autoComplete={false}
                        />
                    </FormGroup>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={checkout}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='summary-page'>
                {/* 
                    1. Flex row
                    2. Detail Product
                    3. Whatsapp No
                    4. Payment Summary
                    5. Total
                    6. Checkout Button --> Popup to input PIN
                    7. Wait for result
                    8. Generate result page in this same component
                    9. Redirect to homepage if there is no token
                */}
                <h1 className='summary-page-title'>
                    Rincian Pesanan
                </h1>
                <div className="summary-page-body">
                    <div className="summary-page-body-desc">
                        <div className="summary-page-body-desc-image">
                            <img src={ summary.serviceIconPath } alt="" />
                        </div>

                        <div className="summary-page-body-desc-container">
                            <div className="summary-page-body-desc-title">
                                <h3>
                                    { summary.serviceTitle }
                                </h3>
                            </div>

                            <div className="summary-page-body-desc-desc" dangerouslySetInnerHTML={{ __html: summary.serviceDesc }}>
                                
                            </div>
                        </div>

                    </div>
                    <div className="summary-page-body-detail">
                        <div className="summary-page-body-detail-phone-no">
                            <label htmlFor="phone-no-input" className='phone-no-label'>
                                <FontAwesomeIcon icon={ ['fab', 'whatsapp'] } /> No. Whatsapp 
                            </label>
                            <div className="phone-no-input">
                                <FormControl 
                                    name='phoneNo'
                                    as='input'
                                    type='tel' 
                                    defaultValue={ summary.phoneNo }
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="summary-page-body-detail-price">
                            <div className="price-item">
                                <span className="price-item-label"> Harga </span>
                                <span className='price-item-price'> { numberFormatting(summary.totalPrice) } </span>
                            </div>
                            <hr />
                            <div className="price-item total">
                                <span className="price-item-label"> Total </span>
                                <span className='price-item-price'> { numberFormatting(summary.totalPrice) } </span>
                            </div>

                            <div className="summary-btn-container">
                                <button className={`btn-checkout`} onClick={ () => handleShow() }>
                                    Bayar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="summary-page">
            <LoadingPage />
        </div>
    );
}

export default SummaryPage;