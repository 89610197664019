const Testimony = () => {
    return [
        {
            reviewer: 'T***i',
            review: 'Sangat membantu ketika buta hukum 👍👍👍👍👍👍',
            rating: 5,
        },
        {
            reviewer: 'J***a',
            review: 'Ok sih, konsultasi menggunakan Landack. terlayani dengan baik.',
            rating: 4
        },
        {
            reviewer: 'C***e',
            review: 'Pelayanannya sangat bagus',
            rating: 4
        }
    ]
}

export default Testimony;