import React from 'react';

import { numberFormatting } from '../../../Util/Util';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import './BusinessEntityList.scss';
import { Link } from 'react-router-dom';

const BusinessEntityList = ({ serviceData }) => {
    const gaEventTracker = useAnalyticsEventTracker('Pendirian Badan Usaha');
    const { data, addOns } = serviceData;
    const service = serviceData.data;
    const { virtualOffice } = serviceData.addOns;

    return (
        <div className="business-entity-container">
            <div className="business-entity-list">
                {
                    service.map((item, index) => {
                        return (
                            <div key={index} className="business-entity-item">
                                <div className="base-price">
                                    <span className="business-entity-name">
                                        { item.name }
                                    </span>
                                </div>

                                {
                                    item.priceList.map((value, key) => {
                                        return  (
                                            <div key={key} className="additional-price">
                                                {/* 
                                                    price-label
                                                    order-btn
                                                */}
                                                <div className="price-info">
                                                    <span className="price-label">
                                                        { value.business_entity_name }
                                                    </span>
                                                    {
                                                        value.hasOwnProperty('markup_price') && value.markup_price > 0 ? (
                                                            <span className="price-number real">
                                                                { numberFormatting(value.markup_price) }
                                                            </span>
                                                        ) : ''
                                                    }
                                                    <span className="price-number">
                                                        { numberFormatting(value.price) }
                                                    </span>
                                                </div>
                                                {/* <div className="order-btn-container">
                                                    <a 
                                                        target="_blank" 
                                                        href={value.waLink} 
                                                        className="btn-order"
                                                        onClick={gaEventTracker('Click Order Button', value.name)}
                                                    >
                                                        Pesan
                                                    </a>
                                                </div> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>

            <div className="business-entity-extra">
                <div className="business-entity-add-ons">
                    <div className="add-ons-title">
                        <h2>
                            Tambahan
                        </h2>
                    </div>
                    <div className="add-ons-list">
                        <div className="add-ons-items">
                            Stempel: <span className='add-ons-price'>
                                { numberFormatting(addOns.stamplePrice) }
                            </span>
                        </div>
                        <div className="add-ons-items">
                            Kartu Nama: <span className='add-ons-price'>
                                { numberFormatting(addOns.cardNamePrice) } / orang
                            </span>
                        </div>
                    </div>
                </div>

                <div className="business-entity-vo">
                    <div className="vo-title">
                        <h2>
                            Pilihan Lokasi VO
                        </h2>
                    </div>
                    <div className="vo-list">
                        {
                            virtualOffice.map((item, index) => {
                                return (
                                    <ul key={index} className="vo-items">
                                        <li className="item-title">
                                            { item }
                                        </li>
                                        {/* <ul className="item-list">
                                            <li className='item-list-item'>
                                                Cengkareng Business District
                                            </li>
                                            <li className='item-list-item'>
                                                Rumah Duka Jelambar
                                            </li>
                                        </ul> */}
                                    </ul>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessEntityList;