import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        title: state.metatag.title,
        description: state.metatag.description,
        keywords: state.metatag.keywords
    };
}

const SNI = (props) => {
    const [ title, setTitle ] = useState("Landack. - Sobatmu Dalam Hukum");
    const [ description, setDescription ] = useState("Penyedia platform jasa konsultasi dan dokumen hukum dengan harga terjangkau dan akses yang mudah.");
    const [ keywords, setKeywords ] = useState("konsultasi online,landak,landack,landak hukum,aplikasi hukum,hukum online,landak indonesia,landak id,konsul hukum,tanya hukum,tips hukum,konsultasi legal,jasa pembuatan perjanjian,jasa somasi,jasa hukum,pembuatan perjanjian,jasa review dokumen,review dokumen,review perjanjian,konsultasi hukum,Landak.,Hukum Mobile,App Legal,konsultasi hukum online,kegiatan ekonomi yang bergerak dalam layanan konsultasi hukum biasanya berbentuk,konsultasi hukum gratis,konsultasi hukum online gratis,jasa konsultasi hukum,aplikasi konsultasi hukum,jasa konsultasi hukum online,Hukum,Konsul legal,Perjanjian,Somasi,Konsul sekarang,Konsultasi via Chat,Perbuatan Perjanjian,Perjanjian Kerahasiaan,Surat Somasi,Jawaban Somasi,Legal Due Diligence,Uji Tuntas Hukum,LDD,layanan hukum,cek dokumen hukum,buat dokumen hukum,cek dokumen legal,buat dokumen legal,aplikasi konsultasi hukum online,layanan hukum online,legal,layanan legal,jasa legal,konsultasi,pengacara,aplikasi jasa hukum,kebutuhan hukum,kebutuhan legal,jasa hukum terdekat,jasa legal terdekat,jasa pengacara,jasa hukum terpercaya,jasa hukum aman,jasa hukum mudah,jasa hukum murah,legal software, software lawyer");

    useEffect( () => {
        setTitle(props.title);
        setDescription(props.description);
        setKeywords(props.keywords);
    }, [props.title, props.description, props.keywords]);

    return (
        <Helmet>
            <title> { title } </title>
            <meta
                name="description"
                content={ description }
            />
            <meta 
                name="keywords" 
                content={ keywords } 
            />
        </Helmet>
    );
}

export default connect(mapStateToProps)(SNI);