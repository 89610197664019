const ContactInfo = () => {
    const companyInfo = {
        'maps'   : {
            link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d833.9300469486194!2d106.89958895793164!3d-6.149204680352867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5b3a1a743a3%3A0x1decd4b70f356437!2sLandack.!5e0!3m2!1sen!2sid!4v1674929991085!5m2!1sen!2sid',
            image: 'location-map.png'
        },
        'address': "Jalan Gading Batavia LC11 No.26, RT.1/RW.7, Kelapa Gading Barat, Kecamatan Kelapa Gading, Kota Jakarta Utara, Daerah Khusus Ibukota Jakarta 14240",
        'phoneno': '+62 813-1777-1221',
        'email': 'info@landack.id',
        'socmed': [
            {
                name: "Instagram",
                link: "https://instagram.com/landack.id/",
                show: true,
                faIcon: ['fab', 'instagram']
            },
            {
                name: "Facebook",
                link: 'https://www.facebook.com/people/Landack/100088056559882/',
                show: true,
                faIcon: ['fab', 'facebook']
            },
            {
                name: "LinkedIn",
                link: 'https://www.linkedin.com/company/landack/',
                show: false,
                faIcon: ['fab', 'linkedin']
            },
            {
                name: "Twitter",
                link: 'https://twitter.com/Landackid/',
                show: false,
                faIcon: ['fab', 'twitter']
            },
            {
                name: "Youtube",
                link: 'https://www.youtube.com/channel/UCqVBdkmFmX2P912HELZmr4w',
                show: false,
                faIcon: ['fab', 'youtube']
            },
            {
                name: "Tiktok",
                link: 'https://www.tiktok.com/@landack.id/',
                show: false,
                faIcon: ['fab', 'tiktok']
            }
        ],
        'dirjen': {
            title: 'Layanan Pengaduan Konsumen',
            company: 'PT Inspirasi Teknologi Bangsa',
            email: 'info@landack.id',
            description: [
                'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
                'Kementrian Perdagangan Republik Indonesia'
            ],
            whatsapp: '+62 853-1111-1010'
        }
    };

    const text = 'Selamat datang di layanan Landack!'
    companyInfo.whatsappLink = `whatsapp:send?phone=${companyInfo.phoneno}&text=${text}`;
    companyInfo.prpLink = `https://www.prplawfirm.com`;
    
    return companyInfo;
}

export default ContactInfo;